import { createContext, useContext } from "react";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";

const anchorElementSchema = z
  .object({
    anchor_element_selector: z.string().min(1, "Selector is required"),
    anchor_element_innerText: z.string(),
    "anchor_element_disable_outline?": z.boolean(),
  })
  .optional();

const dialogBoxSchema = z
  .object({
    dialog_width: z.number().min(0),
    dialog_translateX: z.number(),
    dialog_translateY: z.number(),
  })
  .optional();

const stepSchema = z.object({
  id: z.string().optional(),
  url: z
    .union([z.literal(""), z.string().url("Must be a valid URL")])
    .optional()
    .nullable(),
  subtitle: z.string().optional().nullable(),
  title: z.string().min(1, "Title is required"),
  description: z.string().min(1, "Description is required"),

  anchor_element_selector: z.string().min(1, "Selector is required"),
  anchor_element_innerText: z.string().optional().nullable(),
  "anchor_element_disable_outline?": z.boolean().optional().nullable(),
  context_element_selector: z.string().optional().nullable(),

  next_trigger: z.enum(["onPageNav", "dialogButton", "userNav"], {
    errorMap: () => ({ message: "Please select a trigger type" }),
  }),

  dialog_width: z.number().min(0).nullable(),
  dialog_translateX: z.number().nullable(),
  dialog_translateY: z.number().nullable(),
});

const chainedProcessSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const processSchema = z.object({
  id: z.string(),
  name: z.string().min(1, "Name is required"),
  description: z.string().min(1, "Description is required"),
  url: z.string().url("Must be a valid URL"),
  status: z.string(),
  steps: z.array(stepSchema).min(1, "At least one step is required"),
  chained_processes: z.array(chainedProcessSchema).optional(),
});

export const processFormSchema = z.object({
  processGroupId: z.string().min(1, "Please select a process group"),
  process: processSchema,
});

export type ProcessFormValues = z.infer<typeof processFormSchema>;
export type StepFormValues = z.infer<typeof stepSchema>;

const ProcessFormContext =
  createContext<UseFormReturn<ProcessFormValues> | null>(null);

export const useProcessForm = () => {
  const context = useContext(ProcessFormContext);
  if (!context) {
    throw new Error("useProcessForm must be used within a ProcessFormProvider");
  }
  return context;
};

export const ProcessFormProvider = ProcessFormContext.Provider;
