import React, { useContext, useEffect } from "react";

import { Modal, Box, Typography, Stack, Button } from "@mui/material";

import { ModalStyle2 } from "src/Components/modalStyle";

import { CreateProcess, UpdateProcess } from "src/API/getUserData";

import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "src/Components/ui/Input";

import {
  LoadingMessage,
  SucceessMessage,
  ErrorMessage,
} from "../../dashboardModals/modalComponents/messageComponents";
import { Process } from "src/types/Process";
import UserContext from "src/Contexts/userContext";

export const processSchema = z.object({
  name: z.string().min(1, "Process name is required"),
});

type ProcessFormValues = z.infer<typeof processSchema>;

export default function ProcessModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  processGroupId: string;
  process: Process | null;
  callback(): any;
}) {
  const { logout } = useContext(UserContext);

  const processForm = useForm<ProcessFormValues>({
    resolver: zodResolver(processSchema),
    defaultValues: {
      name: "",
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = processForm;

  useEffect(() => {
    if (props.process) {
      processForm.reset({
        name: props.process.name || "",
      });
    }
  }, []);

  /* modal controls */
  const [modalMessage, setModalMessage] = React.useState<any>(null);

  const createProcess = () => {
    setModalMessage(LoadingMessage());

    CreateProcess(
      props.processGroupId,
      {
        ...props.process,
        ...processForm.getValues(),
      },
      logout
    )
      .then((response: any) => {
        setModalMessage(
          SucceessMessage("Process created successfully", props.setOpen)
        );
        /* update the appropriate context so that changes are reflected */
        props.callback();

        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((error) => {
        setModalMessage(
          ErrorMessage(
            error.message,
            props.setOpen as React.Dispatch<React.SetStateAction<boolean>>
          )
        );
      });
  };

  const updateProcess = () => {
    setModalMessage(LoadingMessage());

    UpdateProcess(
      {
        process_group_id: props.process?.process_group_id,
        id: props.process?.id,
        ...processForm.getValues(),
      } as Process,
      logout
    )
      .then((response: any) => {
        setModalMessage(
          SucceessMessage("Process created successfully", props.setOpen)
        );
        /* update the appropriate context so that changes are reflected */
        props.callback();

        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((error) => {
        setModalMessage(
          ErrorMessage(
            error.message,
            props.setOpen as React.Dispatch<React.SetStateAction<boolean>>
          )
        );
      });
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>
        {!modalMessage ? (
          <Box>
            <Typography
              id="add-modal-description"
              variant="h6"
              color="secondary"
              gutterBottom
              sx={{ mt: 1 }}
            >
              {props.process ? "Edit " : "Add a new "} process
            </Typography>

            <FormProvider {...processForm}>
              <form
                onSubmit={handleSubmit(
                  props.process ? updateProcess : createProcess
                )}
                noValidate={true}
              >
                <Box>
                  <Input
                    name="name"
                    color="secondary"
                    id="processName"
                    label="Enter Process Name"
                    type="search"
                    variant="standard"
                    fullWidth
                    margin="dense"
                  />
                  <br />
                  <br />
                </Box>
                <Stack direction="row" sx={{ bottom: 0 }}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                    fullWidth
                    sx={{ m: 1 }}
                  >
                    SUBMIT
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    sx={{ m: 1 }}
                    onClick={() => {
                      props.setOpen(false);
                    }}
                  >
                    CANCEl
                  </Button>
                </Stack>
              </form>{" "}
            </FormProvider>
          </Box>
        ) : (
          modalMessage
        )}
      </Box>
    </Modal>
  );
}
