import React, { useContext } from "react";

import {
  Modal,
  Box,
  Alert,
  Typography,
  Stack,
  Button,
  LinearProgress,
  TextField,
} from "@mui/material";

import { ModalStyle2 } from "src/Components/modalStyle";
import DashboardContext from "src/Contexts/dashboardContext";
import {
  DeleteCollection,
  DeleteMember,
  DeleteProcessGroup,
  DeleteTeam,
  FetchOrgCollections,
  FetchOrgMembers,
  FetchOrgTeams,
} from "src/API/getUserData";
import { Collection } from "src/types/Collection";
import { Member } from "src/types/Member";
import { Team } from "src/types/Team";
import { ProcessGroup } from "src/types/ProcessGroup";
import UserContext from "src/Contexts/userContext";

export default function RemoveItemModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  itemType: string;
  item: Member | Team | Collection | ProcessGroup;
}) {
  const { logout } = useContext(UserContext);

  const { setOrgMembers, setOrgCollections, setOrgTeams } =
    useContext(DashboardContext);
  const [confirmName, setConfirmName] = React.useState<string>("");

  /* modal controls */
  const [modalMessage, setModalMessage] = React.useState<any>(null);
  const handleSubmit = () => {
    setModalMessage(
      <Box sx={{ width: "100%" }}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {/* {props.info.message} */}
          Loading...
        </Typography>
        <br />
        <LinearProgress color="secondary" />
        <br />
      </Box>
    );

    switch (props.itemType) {
      case "member":
        {
          DeleteMember(props.item.id, logout)
            .then((response: any) => {
              setModalMessage(SucceessMessage("member"));

              FetchOrgMembers(logout)
                .then((result: Member[]) => {
                  setOrgMembers(result);
                })
                .catch((error) => {
                  if (error.error === "Invalid token") {
                    console.log("invalid tok");
                  }
                });
              FetchOrgTeams(logout).then((result: Team[]) => {
                setOrgTeams(result);
                setTimeout(() => {
                  props.setOpen(false);
                }, 5000);
              });
            })
            .catch((error: any) => {
              setModalMessage(ErrorMessage(error.message, props.setOpen));
            });
        }
        break;

      case "team":
        {
          DeleteTeam(props.item.id, logout)
            .then((response: any) => {
              setModalMessage("team");

              FetchOrgTeams(logout).then((result: Team[]) => {
                setOrgTeams(result);
                setTimeout(() => {
                  props.setOpen(false);
                }, 5000);
              });
            })
            .catch((error: any) => {
              setModalMessage(ErrorMessage(error.message, props.setOpen));
            });
        }
        break;

      case "collection":
        {
          DeleteCollection(props.item.id, logout)
            .then((response: any) => {
              setModalMessage(SucceessMessage("collection"));

              FetchOrgCollections(logout).then((result: Collection[]) => {
                setOrgCollections(result);
                setTimeout(() => {
                  props.setOpen(false);
                }, 5000);
              });
            })
            .catch((error: any) => {
              setModalMessage(ErrorMessage(error.message, props.setOpen));
            });
        }
        break;

      case "process_group":
        {
          DeleteProcessGroup(props.item.id, logout)
            .then((response: any) => {
              setModalMessage(SucceessMessage("process group"));

              FetchOrgCollections(logout).then((result: Collection[]) => {
                setOrgCollections(result);
                setTimeout(() => {
                  props.setOpen(false);
                }, 5000);
              });
            })
            .catch((error: any) => {
              setModalMessage(ErrorMessage(error.message, props.setOpen));
            });
        }
        break;
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>
        {!modalMessage ? (
          <Box>
            <Alert severity={"warning"}>
              {"This is a destructive action and cannot be undone"}
            </Alert>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {/* {props.info.message} */}
              Are you sure you want to remove {props.itemType.replace(
                "_",
                " "
              )}{" "}
              with ID: <b>{props.item.id}</b>?
            </Typography>
            <br />
            {/* force confirmation */}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {/* {props.info.message} */}
              To confirm, enter the ID of the {props.itemType} to be deleted:
            </Typography>
            <TextField
              color="secondary"
              id="confirmDelete"
              label="Confirm"
              value={confirmName}
              type="search"
              variant="standard"
              multiline
              style={{
                margin: 10,
                width: "100%",
                background: "#FFFFFF",
              }}
              onChange={(event: any) => {
                setConfirmName(event.target.value);
              }}
            />
            <br />

            <Stack direction="row" sx={{ bottom: 0 }}>
              <Button
                disabled={confirmName !== props.item.id}
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                sx={{ m: 1 }}
              >
                SUBMIT
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                sx={{ m: 1 }}
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                CANCEl
              </Button>
            </Stack>
          </Box>
        ) : (
          modalMessage
        )}
      </Box>
    </Modal>
  );
}

function SucceessMessage(itemType: string) {
  return (
    <Box sx={{ width: "100%" }}>
      <Alert severity={"success"}>{"SUCCESS"}</Alert>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {itemType} deleted sucessfully
      </Typography>
    </Box>
  );
}

function ErrorMessage(
  errorMessage: string,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  return (
    <Box sx={{ width: "100%" }}>
      <Alert severity={"error"}>{"ERROR"}</Alert>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {errorMessage}
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        sx={{ m: 1 }}
        onClick={() => {
          setOpen(false);
        }}
      >
        OKAY
      </Button>
    </Box>
  );
}

