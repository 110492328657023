import React, { useContext } from "react";

import {
  Modal,
  Box,
  Alert,
  Typography,
  Stack,
  Button,
  LinearProgress,
} from "@mui/material";

import { ModalStyle2 } from "../../../../Components/modalStyle";

import { UpdateProcess } from "src/API/getUserData";
import { Process } from "src/types/Process";
import UserContext from "src/Contexts/userContext";

export default function UpdateProcessModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  info: { action: string; processInfo: Process };
}) {
  console.log(props.info.processInfo);
  const { logout } = useContext(UserContext);

  const handleSubmit = () => {
    setMessage(<Loading />);

    const submitObj = props.info.processInfo;

    console.log(submitObj);

    UpdateProcess(submitObj, logout)
      .then((response: Process) => {
        setMessage(
          <SubmitInfo
            setOpen={props.setOpen}
            type={"success"}
            message={"Process updated successfully"}
          />
        );
        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((error) => {
        setMessage(
          <SubmitError setOpen={props.setOpen} message={error.error} />
        );
      });
  };

  const [message, setMessage] = React.useState<any>(
    props.info.action === "info" ? (
      <SubmitInfo
        setOpen={props.setOpen}
        type={"info"}
        message={"Correct errors or change to 'Draft' status to submit"}
      />
    ) : (
      <ValidProcess
        action={props.info.action}
        setOpen={props.setOpen}
        handleSubmit={handleSubmit}
      />
    )
  );

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>{message && <Box>{message}</Box>}</Box>
    </Modal>
  );
}

function ValidProcess({
  action,
  setOpen,
  handleSubmit,
}: {
  action: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: any;
}) {
  return (
    <Box>
      <Alert severity={"info"}>Are you sure?</Alert>
      {action === "edit" && (
        <Typography variant="h6" p={2}>
          Please confirm your changes; existing values will be permanently
          overwritten.
        </Typography>
      )}
      {action === "add" && (
        <Typography variant="h6" p={2}>
          Click 'Submit' to add this process.
        </Typography>
      )}
      <Stack direction="row" sx={{ bottom: 0 }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          fullWidth
          sx={{ m: 1 }}
        >
          SUBMIT
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          fullWidth
          sx={{ m: 1 }}
          onClick={() => {
            setOpen(false);
          }}
        >
          CANCEl
        </Button>
      </Stack>
    </Box>
  );
}

function Loading() {
  return (
    <Box sx={{ width: "100%" }}>
      <Alert severity={"info"}>Loading...</Alert>
      <br />
      <Typography id="modal-modal-description" variant="h6" sx={{ mt: 2 }}>
        Saving changes...
      </Typography>
      <br />
      <LinearProgress color="secondary" />
      <br />
      <br />
    </Box>
  );
}

function SubmitInfo({
  setOpen,
  type,
  message,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  message: string;
}) {
  return (
    <Box sx={{ width: "100%" }}>
      {type === "success" && <Alert severity={"success"}>{"SUCCESS"}</Alert>}
      {type === "info" && (
        <Alert severity={"info"}>{"INCOMPLETE INFORMATION"}</Alert>
      )}
      <Typography id="modal-modal-description" variant="h6" sx={{ mt: 2 }}>
        {message}
      </Typography>
      <br />
      <Button
        color="secondary"
        variant="outlined"
        fullWidth
        onClick={() => {
          setOpen(false);
        }}
      >
        OKAY
      </Button>
    </Box>
  );
}

function SubmitError({
  setOpen,
  message,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
}) {
  return (
    <Box sx={{ width: "100%" }}>
      <Alert severity={"error"}>{"ERROR"}</Alert>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {message}
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        sx={{ m: 1 }}
        onClick={() => {
          setOpen(false);
        }}
      >
        OKAY
      </Button>
    </Box>
  );
}
