import React from "react";

import {
  Stack,
  Paper,
  Divider,
  FormControl,
  FormLabel,
  Typography,
  Box,
} from "@mui/material";

import { Input } from "src/Components/ui/Input";
import { ToggleGroup } from "src/Components/ui/ToggleGroup";
import { DialogBoxControls } from "./dialogControls";

export const StepForm: React.FC<{
  stepIndex: number | null;
}> = ({ stepIndex }) => {
  const stepPath = `process.steps.${stepIndex || 0}`;

  return typeof stepIndex === "number" ? (
    <Paper
      elevation={1}
      key={stepPath}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        bgcolor: "primary.light",
        border: 1,
        borderColor: "secondary.main",
      }}
    >
      <Stack spacing={2}>
        <Input
          variant="outlined"
          name={`${stepPath}.url`}
          color="secondary"
          type="search"
          size="small"
          margin="dense"
          label="URL"
        />
        <Divider
          sx={{
            fontSize: "small",
            fontStyle: "italic",
            color: "divider",
          }}
        >
          Placement
        </Divider>
        <Stack spacing={1}>
          <Box
            sx={{
              borderRadius: "5px",
              borderColor: "secondary.main",
              borderStyle: "solid",
              borderWidth: 1,
              backgroundColor: "primary.light",
              p: 1,
            }}
          >
            <Box
              sx={{
                borderRadius: "5px",
                borderColor: "secondary.dark",
                borderStyle: "solid",
                borderWidth: 2,

                p: 1,
              }}
            >
              <Input
                name={`${stepPath}.anchor_element_selector`}
                label="Selector"
                variant="outlined"
                required
                color="secondary"
                type="search"
                size="small"
                margin="dense"
              />

              <Input
                name={`${stepPath}.anchor_element_innerText`}
                variant="outlined"
                label="Text"
                color="secondary"
                type="search"
                size="small"
                margin="dense"
                multiline
                maxRows={3}
              />
              <Typography
                variant="subtitle2"
                color="secondary.dark"
                sx={{ mt: 1 }}
              >
                Anchor Element
              </Typography>
            </Box>
            <Input
              sx={{ mt: 2 }}
              name={`${stepPath}.context_element_selector`}
              variant="outlined"
              label="Context element"
              color="secondary"
              type="search"
              size="small"
              margin="dense"
            />
            <Typography variant="subtitle2" color="secondary" sx={{ mt: 1 }}>
              Context Element
            </Typography>
          </Box>
        </Stack>
        <Divider
          sx={{
            fontSize: "small",
            fontStyle: "italic",
            color: "divider",
          }}
        >
          Navigation
        </Divider>
        <FormControl color="secondary">
          <FormLabel id="buttons-group-label">
            How will the user navigate to the next step?
          </FormLabel>
          <ToggleGroup
            name={`${stepPath}.next_trigger`}
            options={[
              {
                value: "onPageNav",
                label: "Click",
                tooltip: "By clicking the relevant page element",
                tooltipProps: {
                  placement: "top-start",
                  arrow: true,
                },
              },
              {
                value: "dialogButton",
                label: "Next",
                tooltip: "By clicking the 'next' button on the popup",
                tooltipProps: {
                  arrow: true,
                  placement: "top",
                },
              },
              {
                value: "userNav",
                label: "Navigate",
                tooltip: "By navigating to the next page",
                tooltipProps: {
                  arrow: true,
                  placement: "top-end",
                },
              },
            ]}
            fullWidth
            exclusive
            size="small"
            color="secondary"
            id="nextTrigger"
            aria-labelledby="buttons-group-label"
          />
        </FormControl>

        <Divider
          sx={{
            fontSize: "small",
            fontStyle: "italic",
            color: "divider",
          }}
        >
          Positioning
        </Divider>

        <DialogBoxControls stepPath={stepPath} />
      </Stack>
    </Paper>
  ) : (
    <Typography fontStyle="italic" color="divider" variant="h5" pt={5} pb={50}>
      Select a step to view & edit details here
    </Typography>
  );
};

export default StepForm;
