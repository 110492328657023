import React, { useContext, useEffect } from "react";

import { Modal, Box, Typography, Stack, Button } from "@mui/material";

import { ModalStyle2 } from "src/Components/modalStyle";

import {
  CreateProcessGroup,
  FetchProcessGroups,
  UpdateProcessGroup,
} from "src/API/getUserData";
import DashboardContext from "src/Contexts/dashboardContext";

import { ProcessGroup } from "src/types/ProcessGroup";

import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "src/Components/ui/Input";

import {
  LoadingMessage,
  SucceessMessage,
  ErrorMessage,
} from "../../dashboardModals/modalComponents/messageComponents";
import UserContext from "src/Contexts/userContext";

export const processGroupSchema = z.object({
  name: z.string().min(1, "Process Group name is required"),
});

type ProcessGroupFormValues = z.infer<typeof processGroupSchema>;

export default function ProcessGroupModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  collectionId: string;
  processGroup: ProcessGroup | null;
}) {
  const { setOrgProcessGroups } = useContext(DashboardContext);
  const { logout } = useContext(UserContext);

  const processGroupForm = useForm<ProcessGroupFormValues>({
    resolver: zodResolver(processGroupSchema),
    defaultValues: {
      name: "",
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = processGroupForm;

  useEffect(() => {
    if (props.processGroup) {
      processGroupForm.reset({
        name: props.processGroup.name || "",
      });
    }
  }, []);

  /* modal controls */
  const [modalMessage, setModalMessage] = React.useState<any>(null);

  const createProcessGroup = () => {
    setModalMessage(LoadingMessage());

    CreateProcessGroup(
      {
        collection_id: props.collectionId,
        ...props.processGroup,
        ...processGroupForm.getValues(),
      } as ProcessGroup,
      logout
    )
      .then((response: any) => {
        setModalMessage(
          SucceessMessage("Process Group created successfully", props.setOpen)
        );
        /* update the appropriate context so that changes are reflected */
        FetchProcessGroups(props.collectionId, logout).then(
          (result: ProcessGroup[]) => {
            setOrgProcessGroups(result);
          }
        );

        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((error) => {
        setModalMessage(
          ErrorMessage(
            error.message,
            props.setOpen as React.Dispatch<React.SetStateAction<boolean>>
          )
        );
      });
  };

  const updateProcessGroup = () => {
    setModalMessage(LoadingMessage());
    UpdateProcessGroup(
      {
        ...props.processGroup,
        ...processGroupForm.getValues(),
      } as ProcessGroup,
      logout
    )
      .then((response: any) => {
        setModalMessage(
          SucceessMessage("Process Group created successfully", props.setOpen)
        );
        /* update the appropriate context so that changes are reflected */
        FetchProcessGroups(props.collectionId, logout).then(
          (result: ProcessGroup[]) => {
            setOrgProcessGroups(result);
          }
        );

        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((error) => {
        setModalMessage(ErrorMessage(error.message, props.setOpen));
      });
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>
        {!modalMessage ? (
          <Box>
            <Typography
              id="add-modal-description"
              variant="h6"
              color="secondary"
              gutterBottom
              sx={{ mt: 1 }}
            >
              {props.processGroup ? "Edit " : "Add a new "} process group
            </Typography>

            <FormProvider {...processGroupForm}>
              <form
                onSubmit={handleSubmit(
                  props.processGroup ? updateProcessGroup : createProcessGroup
                )}
                noValidate={true}
              >
                <Box>
                  <Input
                    name="name"
                    color="secondary"
                    id="processGroupName"
                    label="Process Group Name"
                    type="search"
                    variant="standard"
                    fullWidth
                    margin="dense"
                  />
                  <br />
                  <br />
                </Box>
                <Stack direction="row" sx={{ bottom: 0 }}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                    fullWidth
                    sx={{ m: 1 }}
                  >
                    SUBMIT
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    sx={{ m: 1 }}
                    onClick={() => {
                      props.setOpen(false);
                    }}
                  >
                    CANCEl
                  </Button>
                </Stack>
              </form>{" "}
            </FormProvider>
          </Box>
        ) : (
          modalMessage
        )}
      </Box>
    </Modal>
  );
}
