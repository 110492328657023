import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import { Link, Paper, Stack, Typography } from "@mui/material";

export default function Footer() {
  return (
    <Box position={"relative"}>
      <Paper
        sx={{
          backgroundColor: "primary.dark",
          flexGrow: 1,
          height: "120px",
        }}
      >
        {" "}
        <Grid container spacing={3}>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <Link href="/contact">
              <b>Contact Us</b>
            </Link>
          </Grid>

          <Grid item xs={4}>
            <Stack>
              <Link href="/privacy-policy">
                <b>Privacy Policy</b>
              </Link>
              {/* <Link href="#">
                <b>Terms of Use</b>
              </Link> */}
            </Stack>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <br />
        <Copyright />
      </Paper>
    </Box>
  );
}

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="divider" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.nextstepknowledge.com/">
        NextStep Knowledge Solutions LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
