import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import IconButton from "@mui/material/IconButton";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { Team } from "src/types/Team";
import TeamMemberModal from "./teamModals/teamMemberModal";
import { Member } from "src/types/Member";

const rowHeaders = ["Members", "Edit Assigmnemt"];

export default function TeamCard(props: { team: Team }) {
  const cards = props.team
    ? [
        { key: "Status", value: props.team.status || "active" },
        {
          key: "Date Created",
          value: props.team.created_at
            ? new Date(props.team.created_at).toLocaleDateString("en-US")
            : "unknown",
        },
      ]
    : [];

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [member, setMember] = React.useState<any>({});

  return (
    <React.Fragment>
      <Paper
        elevation={4}
        sx={{
          p: 2,
          backgroundColor: "secondary.dark",
          display: "flex",
          flexDirection: "column",
          height: "500px",
          overflow: "scroll",
        }}
      >
        <Typography variant="h6" pb={2} align="left" color={"white"}>
          {props.team.name}
        </Typography>

        {!props.team && (
          <Typography
            fontStyle="italic"
            color="divider"
            variant="h5"
            pb={2}
            pt={10}
          >
            Select a Team to view details here
          </Typography>
        )}
        {props.team && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={4} key={props.team.id + " overview"}>
                {cards.map((card: any) => (
                  <Tooltip
                    key={card.key}
                    title={card.info ? card.info : ""}
                    placement="top"
                    arrow
                  >
                    <Card
                      key={card.key}
                      sx={{
                        backgroundColor: "primary.light",
                        mb: 2,
                      }}
                    >
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: "bold" }}
                          color="secondary.main"
                          gutterBottom
                        >
                          {card.key}
                        </Typography>
                        {props.team ? (
                          <Typography
                            variant="h6"
                            component="div"
                            color="primary.dark"
                          >
                            {card.value}
                          </Typography>
                        ) : (
                          <Skeleton variant="rounded" height={70} />
                        )}
                      </CardContent>
                    </Card>
                  </Tooltip>
                ))}
              </Grid>
              <Grid item xs={8} key={props.team.id + " members"}>
                <Card
                  sx={{
                    height: "400px",
                    overflow: "scroll",
                  }}
                >
                  <CardContent>
                    <Table
                      size="small"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          {rowHeaders.map((rowHeader: string) => (
                            <TableCell
                              key={rowHeader}
                              align={rowHeader === "Edit" ? "center" : "left"}
                              sx={{ color: "secondary.main" }}
                            >
                              <b>{rowHeader}</b>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {props.team.members && props.team.members.length > 0 && (
                        <TableBody>
                          {props.team.members.map((row: Member) => (
                            <TableRow key={row.id}>
                              <TableCell>
                                {row.first_name +
                                  (row.last_name ? " " + row.last_name : "")}
                              </TableCell>

                              <TableCell align="right" width={120}>
                                <Tooltip
                                  title={"Edit Process Assignments"}
                                  placement="left"
                                >
                                  <IconButton
                                    aria-label="editTeam"
                                    color="secondary"
                                    onClick={() => {
                                      setMember(row);
                                      setModalOpen(true);
                                    }}
                                  >
                                    <PlaylistAddIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                      {!props.team && (
                        <TableBody>
                          <TableRow>
                            {/* column for each header */}
                            {rowHeaders.map((c, ic) => (
                              <TableCell key={c + ic}>
                                {/* n = 10 rows */}
                                {[...Array(10)].map((r, ir) => (
                                  <Skeleton
                                    key={"column:" + ic + "row:" + ir}
                                    variant="text"
                                    sx={{ width: "100%", fontSize: "1rem" }}
                                  />
                                ))}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
      {modalOpen && (
        <TeamMemberModal
          open={modalOpen}
          setOpen={setModalOpen}
          member={member}
          team={props.team}
        />
      )}
    </React.Fragment>
  );
}
