import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { useEffect } from "react";
import { Team } from "src/types/Team";

import TeamModal from "./adminModals/teamModal";
import RemoveItemModal from "../dashboardModals/removeItemModal";


const rowHeaders = ["Name", "Status", "# Members", "Created On", "Edit"];

export default function TeamTable(props: { teams: Team[] }) {
  const [teamModalOpen, setTeamModalOpen] = React.useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = React.useState<boolean>(false);

  const [selectedTeam, setSelectedTeam] = React.useState<Team | null>(null);

  /* toggle controls */
  const [teamList, setTeamList] = React.useState<Team[]>(
    props.teams.filter((team: Team) => team.status === "active")
  );
  useEffect(() => {
    setTeamList(props.teams.filter((team: Team) => team.status === "active"));
  }, [props.teams]);

  const [hideDisabled, setHideDisabled] = React.useState<boolean>(true);
  const showDisabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let filteredTeams = props.teams.filter(
        (team: Team) => team.status === "active"
      );
      setTeamList(filteredTeams);
    } else {
      setTeamList(props.teams);
    }
    setHideDisabled(!hideDisabled);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box p={2}>
        <Typography fontWeight="bold" color="primary.dark" variant="h5">
          Teams
        </Typography>
        <FormGroup sx={{ mt: -4 }}>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={hideDisabled}
                onChange={showDisabled}
                name="disabled toggle"
              />
            }
            label={hideDisabled ? "Show Disabled" : "Hide Disabled"}
            labelPlacement="start"
          />
        </FormGroup>
      </Box>

      <Stack
        sx={{
          maxHeight: "300px",
          overflow: "scroll",
        }}
      >
        <Table stickyHeader size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              {rowHeaders.map((rowHeader: string) => (
                <TableCell
                  key={rowHeader}
                  align={rowHeader === "Edit" ? "center" : "left"}
                  sx={{ color: "secondary.dark" }}
                >
                  <b>{rowHeader}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {teamList.length > 0 ? (
            <TableBody>
              {teamList.map((row: Team) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    <Chip
                      label={row.status || "active"}
                      sx={{
                        fontWeight: "bold",
                        color:
                          row.status === "active"
                            ? "secondary.main"
                            : "divider",
                      }}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>{row.members ? row.members.length : 0}</TableCell>
                  <TableCell width={150}>
                    {row.created_at
                      ? new Date(row.created_at).toLocaleDateString("en-US")
                      : "unknown"}
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Tooltip title={"Edit Team"} placement="left">
                      <IconButton
                        aria-label="editTeam"
                        color="secondary"
                        onClick={() => {
                          setSelectedTeam(row as Team);
                          setTeamModalOpen(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={"Remove Team"} placement="right">
                      <IconButton
                        aria-label="deleteTeam"
                        color="secondary"
                        onClick={() => {
                          setSelectedTeam(row as Team);
                          setRemoveModalOpen(true);
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                {/* column for each header */}
                {rowHeaders.map((c, ic) => (
                  <TableCell key={ic}>
                    {/* n = 10 rows */}
                    {[...Array(3)].map((r, ir) => (
                      <Skeleton
                        key={"column:" + ic + "row:" + ir}
                        variant="text"
                        sx={{ width: "100%", fontSize: "2.5rem" }}
                      />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Stack>
      <br />
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        fullWidth
        onClick={() => {
          setSelectedTeam(null);
          setTeamModalOpen(true);
        }}
      >
        Add Team
      </Button>
      {teamModalOpen && (
        <TeamModal
          open={teamModalOpen}
          setOpen={setTeamModalOpen}
          team={selectedTeam || null}
        />
      )}
      {removeModalOpen && selectedTeam && (
        <RemoveItemModal
          open={removeModalOpen}
          setOpen={setRemoveModalOpen}
          itemType={"team"}
          item={selectedTeam}
        />
      )}
    </Paper>
  );
}
