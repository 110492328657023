import { createContext } from "react";
import { Organization } from "src/types/Organization";
import { Member } from "src/types/Member";

export interface IUserContext {
  orgInfo: Organization | null;
  memberInfo: Member | null;
  login: (data: any) => void;
  logout: (involuntary?: boolean) => void;
}

const UserContext = createContext<IUserContext>({
  orgInfo: null,
  memberInfo: null,
  login: (data: any) => null,
  logout: (involuntary?: boolean) => null,
});

export default UserContext;
