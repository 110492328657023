import * as React from "react";

import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";

import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

function usageByMember(stepLog: any) {
  // this gives an object with members as keys
  const groups = stepLog.reduce((groups: number[], entry: any) => {
    const member = entry.email;
    if (!groups[member]) {
      groups[member] = 0;
    }
    groups[member] += +1;
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  let data: any = [];
  Object.keys(groups).forEach((member) => {
    data[member] = groups[member];
  });

  return groups;
}

export default function UsageBarChart(data: any) {
  /* get top 6 users */
  let usagedata = usageByMember(data.stepHistory);
  // .sort((a, b) => b.value - a.value)
  // .slice(0, 6);

  let arr: number[] = Object.values(usagedata);
  let max = Math.max(...arr);

  const chartSetting = {
    yAxis: [
      {
        label: "Steps completed",
        max: max,
      },
    ],
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        // transform: "translate(-20px, 0)",
      },
      "& .MuiChartsLegend-series tspan": {
        // fontSize: ".4em !important",
        display: "none",
      },
      "& .MuiChartsLegend-series rect": {
        // width: "5px",
        display: "none",
      },
    },
  };

  const valueFormatter = (value: number | null) => `${value}`;

  let seriesData: any[] = [];
  for (let i = 0; i < Math.min(6, Object.keys(usagedata).length); i++) {
    /* filter out data not associated with a user. This shouldn't happen, but here we are */
    if (Object.keys(usagedata)[i]) {
      seriesData.push({
        dataKey: Object.keys(usagedata)[i],
        label: Object.keys(usagedata)[i],
        valueFormatter,
      });
    }
  }

  return (
    <React.Fragment>
      <Typography fontWeight="bold" color="primary.dark" variant="h5">
        # Steps Performed By Member{" "}
      </Typography>

      {data.stepHistory.length > 0 ? (
        <div style={{ width: "100%", flexGrow: 1, overflow: "hidden" }}>
          <BarChart
            colors={[
              "#ced7dd",
              "#afbdc6",
              "#8fa2af",
              "#788e9d",
              "#607a8c",
              "#546c7a",
              "#445864",
              "#36454f",
              "#253038",
            ]}
            dataset={[{ ...usagedata, xLabel: "User" }]}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "xLabel",
              },
            ]}
            series={seriesData}
            {...chartSetting}
            tooltip={{ trigger: "item" }}
          />
        </div>
      ) : (
        <Stack spacing={1}>
          <br />
          <Stack spacing={10} direction={"row"}>
            {/* make a nice graph skeleton */}
            <Skeleton variant="rectangular" width={10} height={150} />
            {[...Array(7)].map((e, i) => (
              <Skeleton
                key={i}
                variant="rounded"
                width={50}
                height={20 * (i + 1)}
                style={{ marginTop: 20 * (6 - i) }}
              />
            ))}
          </Stack>
          <Skeleton variant="rectangular" height={10} />
        </Stack>
      )}
    </React.Fragment>
  );
}
