import { supabase } from "src/supabase/supabaseClient";
import {
  FunctionsHttpError,
  FunctionsRelayError,
  FunctionsFetchError,
} from "@supabase/supabase-js";
import { Organization } from "src/types/Organization";
import { Member } from "src/types/Member";
import { Team } from "src/types/Team";
import { Collection } from "src/types/Collection";
import { Process } from "src/types/Process";
import { ProcessGroup } from "src/types/ProcessGroup";

async function handleError(
  error: any,
  logout: (involuntary?: boolean) => void
) {
  if (error instanceof FunctionsHttpError) {
    const errorMessage = await error.context.json();
    console.log(errorMessage);
    if (errorMessage.error === "Invalid token") {
      logout(true);
    }
    return errorMessage.error;
  } else if (error instanceof FunctionsRelayError) {
    return "Relay error: " + error.message;
  } else if (error instanceof FunctionsFetchError) {
    return "Fetch error: " + error.message;
  }
}

/* ▼▼▼▼▼▼▼▼▼▼ Member ▼▼▼▼▼▼▼▼▼▼ */

export function CreateMember(
  member: Member,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<Member>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(`members/`, {
      method: "POST",
      body: member,
    });

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

export function FetchUserOrgInfo(logout: (involuntary?: boolean) => void) {
  return new Promise<Organization>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      "fetchUserInfo?infoType=org"
    );

    if (data) resolve(data as Organization);
    if (error) reject(await handleError(error, logout));
  });
}

export function FetchUserMemberInfo(logout: (involuntary?: boolean) => void) {
  return new Promise<Member>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      "fetchUserInfo?infoType=member"
    );

    if (data) resolve(data as Member);
    if (error) reject(await handleError(error, logout));
  });
}

export function FetchOrgMembers(logout: (involuntary?: boolean) => void) {
  return new Promise<Member[]>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke("members/", {
      method: "GET",
    });

    if (data) resolve(data.members as Member[]);
    if (error) reject(await handleError(error, logout));
  });
}

export function UpdateMember(
  member: Member,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<Member>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(`members/`, {
      method: "PUT",
      body: member,
    });

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

export function DeleteMember(
  memberId: string,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<boolean>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `members/${memberId}/`,
      { method: "DELETE" }
    );

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

/* ▲▲▲▲▲▲▲▲▲▲ Member ▲▲▲▲▲▲▲▲▲▲ */
/* ▼▼▼▼▼▼▼▼▼▼ Team ▼▼▼▼▼▼▼▼▼▼ */

export function CreateTeam(
  team: Team,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<Team>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(`teams/`, {
      method: "POST",
      body: team,
    });

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

export function FetchOrgTeams(logout: (involuntary?: boolean) => void) {
  return new Promise<Team[]>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke("teams/", {
      method: "GET",
    });

    if (data) resolve(data.teams as Team[]);
    if (error) reject(await handleError(error, logout));
  });
}

export function UpdateTeam(
  team: Team,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<Team>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(`teams/`, {
      method: "PUT",
      body: team,
    });

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

export function DeleteTeam(
  teamId: string,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<boolean>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `teams/${teamId}/`,
      { method: "DELETE" }
    );

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

export function FetchTeamProcesses(
  teamId: string,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<
    {
      id: string;
      name: string;
      collectionName: string;
      processGroupName: string;
    }[]
  >(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `teams/${teamId}/processes`,
      { method: "GET" }
    );

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

/* ▲▲▲▲▲▲▲▲▲▲ Team ▲▲▲▲▲▲▲▲▲▲ */
/* ▼▼▼▼▼▼▼▼▼▼ Collection ▼▼▼▼▼▼▼▼▼▼ */

export function CreateCollection(
  collection: Collection,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<Collection>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(`collections/`, {
      method: "POST",
      body: collection,
    });

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

export function FetchOrgCollections(logout: (involuntary?: boolean) => void) {
  return new Promise<Collection[]>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke("collections/", {
      method: "GET",
    });

    if (data) resolve(data.collections as Collection[]);
    if (error) reject(await handleError(error, logout));
  });
}

export function UpdateCollection(
  collection: Collection,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<Collection>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(`collections/`, {
      method: "PUT",
      body: collection,
    });

    if (data) {
      resolve(data);
    }

    if (error) reject(await handleError(error, logout));
  });
}

export function DeleteCollection(
  collectionId: string,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<boolean>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `collections/${collectionId}/`,
      { method: "DELETE" }
    );

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

/* ▲▲▲▲▲▲▲▲▲▲ Collection ▲▲▲▲▲▲▲▲▲▲ */
/* ▼▼▼▼▼▼▼▼▼▼ ProcessGroup ▼▼▼▼▼▼▼▼▼▼ */

export function CreateProcessGroup(
  processGroup: ProcessGroup,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<ProcessGroup>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(`processGroups/`, {
      method: "POST",
      body: processGroup,
    });

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

export function FetchProcessGroups(
  collectionId: string,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<ProcessGroup[]>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `collections/${collectionId}/processGroups`,
      { method: "GET" }
    );

    if (data) resolve(data as ProcessGroup[]);
    if (error) reject(await handleError(error, logout));
  });
}

export function UpdateProcessGroup(
  processGroup: ProcessGroup,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<ProcessGroup>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(`processGroups/`, {
      method: "PUT",
      body: processGroup,
    });

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

export function DeleteProcessGroup(
  processGroupId: string,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<boolean>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `processGroups/${processGroupId}/`,
      { method: "DELETE" }
    );

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

/* ▲▲▲▲▲▲▲▲▲▲ ProcessGroup ▲▲▲▲▲▲▲▲▲▲ */
/* ▼▼▼▼▼▼▼▼▼▼ Process ▼▼▼▼▼▼▼▼▼▼ */

export function CreateProcess(
  processGroupId: string,
  process: any,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<Process>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(`processes/`, {
      method: "POST",
      body: { process_group_id: processGroupId, ...process },
    });

    if (data) resolve(data);
    if (error) reject(await handleError(error, logout));
  });
}

export function FetchProcesses(
  processGroupId: string,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<Process[]>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `processGroups/${processGroupId}/processes`,
      { method: "GET" }
    );

    if (data) resolve(data as Process[]);
    if (error) reject(await handleError(error, logout));
  });
}

export function FetchProcess(
  processId: string,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<Process>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(
      `processes/${processId}/`,
      {
        method: "GET",
      }
    );

    if (data) resolve(data as Process);
    if (error) reject(await handleError(error, logout));
  });
}

export function UpdateProcess(
  process: Process,
  logout: (involuntary?: boolean) => void
) {
  return new Promise<Process>(async (resolve, reject) => {
    const { data, error } = await supabase.functions.invoke(`processes/`, {
      method: "PUT",
      body: process,
    });

    if (data) resolve(data as Process);
    if (error) reject(await handleError(error, logout));
  });
}

/* ▲▲▲▲▲▲▲▲▲▲ Process ▲▲▲▲▲▲▲▲▲▲ */
/* ▼▼▼▼▼▼▼▼▼▼ Logs ▼▼▼▼▼▼▼▼▼▼ */

/* ▲▲▲▲▲▲▲▲▲▲ Logs ▲▲▲▲▲▲▲▲▲▲ */
