import React from "react";

import { Autocomplete, Paper, Stack, TextField } from "@mui/material";

import { StepList } from "./stepList";
import { useProcessForm } from "./context/ProcessFormContext";
import { Input } from "src/Components/ui/Input";
import { ToggleGroup } from "src/Components/ui/ToggleGroup";
import { Controller } from "react-hook-form";

import { ChainedProcess, type Process } from "src/types/Process";

export const ProcessFC: React.FC<{
  groupProcesses: ChainedProcess[];
}> = ({ groupProcesses }) => {
  const form = useProcessForm();

  return (
    <Paper elevation={4} sx={{ p: 2 }}>
      <Stack direction={"row"} spacing={2} width={"100%"} p={1} flex={1}>
        <Input
          color="secondary"
          name="process.description"
          label="Description"
          disabled={!form.watch("process.steps")}
          required
        />
        <Input
          color="secondary"
          name="process.url"
          label="Initial URL"
          disabled={!form.watch("process.steps")}
          required
        />
        <ToggleGroup
          fullWidth
          name="process.status"
          disabled={!form.watch("process.steps")}
          exclusive
          options={[
            { value: "disabled", label: "Disabled" },
            { value: "draft", label: "Draft" },
            { value: "active", label: "Active" },
          ]}
        />
      </Stack>

      <StepList />
      <Controller
        name="process.chained_processes"
        control={form.control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            sx={{ p: 2 }}
            multiple
            limitTags={5}
            id="chained-processes"
            options={(groupProcesses || []).filter(
              (process) => process.id !== form.watch("process.id")
            )}
            onChange={(_, newValue) => field.onChange(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: ChainedProcess) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                color="secondary"
                variant="standard"
                label="Chained Processes"
                placeholder="Add Chained Processes"
              />
            )}
          />
        )}
      />
    </Paper>
  );
};
