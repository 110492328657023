import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import EmailIcon from "@mui/icons-material/Email";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import emailjs from "@emailjs/browser";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { validateEmail } from "../../Helpers/helpers";
import AppContext from "../../Contexts/appContext";
import Footer from "../../Components/footer";
import { NextStepButton } from "src/Config/styling";

export default function ContactPage() {
  const { setModalInfo } = useContext(AppContext);

  const [name, setName] = useState("");
  const [nameFormatError, setNameFormatError] = useState("");
  useEffect(() => {
    setNameFormatError("");
  }, [name]);

  const [email, setEmail] = useState("");
  const [emailFormatError, setEmailFormatError] = useState("");
  useEffect(() => {
    setEmailFormatError("");
  }, [email]);

  const [message, setMessage] = useState("");
  const [messageFormatError, setMessageFormatError] = useState("");
  useEffect(() => {
    setMessageFormatError("");
  }, [message]);

  const onInputChange = (setter: any) => (e: any) => {
    setter(e.target.value);
  };

  const sendEmail = (e: any) => {
    e.preventDefault();

    /* perform some simple validations before allowing submission */
    let isError = false;
    if (name.length === 0) {
      setNameFormatError("Please enter your name");
      isError = true;
    }
    if (!validateEmail(email)) {
      setEmailFormatError("Please enter a valid email address");
      isError = true;
    }
    if (message.length < 20) {
      setMessageFormatError(
        "Please enter at least 20 characters in your message"
      );
      isError = true;
    }
    if (isError) {
      return;
    }

    /* emailjs expects form data in the submission, so we need to recreate a simple form */
    var formData = document.createElement("form");

    let form_email = document.createElement("input");
    form_email.type = "text";
    form_email.name = "user_email";
    form_email.value = email;

    let form_name = document.createElement("input");
    form_name.type = "email";
    form_name.name = "user_name";
    form_name.value = name;

    let form_message = document.createElement("textarea");
    form_message.name = "message";
    form_message.value = message;

    // myform.appendChild(product);
    formData.appendChild(form_name);
    formData.appendChild(form_email);
    formData.appendChild(form_message);

    emailjs
      .sendForm("nextstep_contact", "website_contact_from", formData, {
        publicKey: "FOCw3N80jpUHGqaOz",
      })
      .then(() => {
        setModalInfo({
          severity: "success",
          open: true,
          message: "Message sent successfully - we'll be in touch shortly!",
        });
        setName("");
        setEmail("");
        setMessage("");
        setTimeout(() => {
          setModalInfo({
            open: false,
          });
        }, 5000);

        console.log("SUCCESS!");
      })
      .catch((error) => {
        setModalInfo({
          open: true,
          message:
            "Unable to send message right now - please try again later or contact us via email",
        });
        console.log("Error: ", error.text);
      });
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
        }}
      >
        <Container maxWidth={false} sx={{ width: "100%" }}>
          <Paper
            elevation={4}
            sx={{
              p: {
                xs: 1,
                md: 10,
              },
              mx: {
                xs: 1,
                md: 10,
              },
              my: 8,

              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h4" align="center" p={3}>
              Contact Us
            </Typography>
            <Typography variant="body1" align="center">
              Ready to get started? Questions or feedback?
            </Typography>
            <br />
            <Typography variant="body2" align="center">
              Contact us via email or the form below. We look forward to hearing
              from you!
            </Typography>
            <Stack direction={"row"} justifyContent={"center"}>
              <Tooltip title={"Send Email"} placement="bottom" arrow>
                <IconButton
                  size="small"
                  edge="end"
                  aria-label="sendEmail"
                  onClick={() => {
                    window.location.href =
                      "mailto:nextstep.knowledge.solutions@gmail.com";
                  }}
                >
                  <EmailIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Copy Email Address"} placement="bottom" arrow>
                <IconButton
                  size="small"
                  edge="end"
                  aria-label="copyEmail"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "nextstep.knowledge.solutions@gmail.com"
                    );
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <br />

            <form onSubmit={sendEmail}>
              <FormControl sx={{ width: "80%" }}>
                <TextField
                  required
                  error={nameFormatError !== ""}
                  id="user_name"
                  label="name"
                  value={name}
                  size="small"
                  margin="normal"
                  style={{
                    width: "100%",
                  }}
                  onChange={onInputChange(setName)}
                  helperText={nameFormatError}
                />
                <TextField
                  required
                  error={emailFormatError !== ""}
                  id="user_email"
                  label="email"
                  value={email}
                  size="small"
                  margin="normal"
                  style={{
                    width: "100%",
                  }}
                  onChange={onInputChange(setEmail)}
                  helperText={emailFormatError}
                />

                <TextField
                  required
                  error={messageFormatError !== ""}
                  id="message"
                  label="message"
                  value={message}
                  size="small"
                  margin="normal"
                  multiline
                  rows={3}
                  style={{
                    width: "100%",
                  }}
                  onChange={onInputChange(setMessage)}
                  helperText={messageFormatError}
                />
                <NextStepButton type="submit" variant="contained" size="small">
                  Submit
                </NextStepButton>
              </FormControl>
            </form>
          </Paper>
        </Container>
        <Footer />
      </Box>
    </Box>
  );
}
