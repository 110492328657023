import React, { useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TeamCard from "./teamCard";
import DashboardContext from "../../../Contexts/dashboardContext";
import { Typography, ListItemText, ListItemButton, Box } from "@mui/material";
import { NextStepList, SearchField } from "../../../Config/styling";
import PageTitle from "../title";
import { Team } from "src/types/Team";

export default function Teams() {
  const { orgTeams } = useContext(DashboardContext);

  const [teamOptions, setTeamOptions] = React.useState<any[]>(
    orgTeams.filter((team: Team) => team.status === "active")
  );
  React.useEffect(() => {
    setTeamOptions(orgTeams.filter((team: Team) => team.status === "active"));
  }, [orgTeams]);

  const [selectedTeam, setSelectedTeam] = React.useState<any>(teamOptions[0]);

  /* team selector list controls */
  const handleListItemClick = (team: Team) => {
    setSelectedTeam(team);
  };

  /* filter member list based on search */
  React.useState<any[]>(teamOptions);

  const handleSearchChange = (e: any) => {
    /* handle clear case */
    if (e.target.value === "") {
      setTeamOptions(teamOptions);
    } else {
      const tempTeamList = [...teamOptions];

      let filteredTeamList = tempTeamList.filter((team: any) =>
        (team.display_name || team.id)
          .toLowerCase()
          .trim()
          .includes(e.target.value.toLowerCase().trim())
      );
      setTeamOptions(filteredTeamList);
    }
  };

  return (
    <Box width={"100%"} height={"93vh"} overflow={"scroll"}>
      <PageTitle
        title={"Teams"}
        description={"Manage team membership and process asignments."}
      />
      <CssBaseline />
      <Grid container spacing={4} p={4}>
        {" "}
        <Grid item xs={4}>
          <Paper
            elevation={0}
            sx={{
              border: "lightgray solid 1px",
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "500px",
            }}
          >
            <Typography variant="h6" pb={2} align="left" color={"secondary"}>
              Select a team
            </Typography>

            <SearchField
              id="outlined-search"
              label="Search for member"
              type="search"
              size="small"
              sx={{
                width: "100%",
                background: "#FFFFFF",
              }}
              onChange={handleSearchChange}
            />
            <NextStepList component="nav" aria-label="team search">
              {teamOptions.map((team: any) => (
                <ListItemButton
                  key={team.id}
                  selected={selectedTeam === team}
                  onClick={() => handleListItemClick(team)}
                >
                  <ListItemText primary={team.name} />
                </ListItemButton>
              ))}
              {teamOptions.length === 0 && <i>No teams found</i>}
            </NextStepList>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <TeamCard team={selectedTeam} />
        </Grid>
      </Grid>
    </Box>
  );
}
