import React, { useContext, useEffect } from "react";

import {
  Modal,
  Box,
  Typography,
  Stack,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";

import { ModalStyle2 } from "src/Components/modalStyle";

import { CreateTeam, FetchOrgTeams, UpdateTeam } from "src/API/getUserData";
import DashboardContext from "src/Contexts/dashboardContext";

import { Team } from "src/types/Team";

import { Controller, FormProvider, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "src/Components/ui/Input";
import { ToggleGroup } from "src/Components/ui/ToggleGroup";

import {
  LoadingMessage,
  SucceessMessage,
  ErrorMessage,
} from "../../dashboardModals/modalComponents/messageComponents";
import UserContext from "src/Contexts/userContext";
import { Member } from "src/types/Member";
import { Collection } from "src/types/Collection";

export const teamSchema = z.object({
  name: z.string().min(1, "Team name is required"),
  status: z.enum(["active", "disabled"], {
    errorMap: () => ({ message: "Please select a status" }),
  }),
  members: z.array(z.object({ id: z.string(), name: z.string() })).optional(),
  collections: z
    .array(z.object({ id: z.string(), name: z.string() }))
    .optional(),
});

type TeamFormValues = z.infer<typeof teamSchema>;

export default function TeamModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  team: Team | null;
}) {
  const { logout } = useContext(UserContext);
  const { orgMembers, orgCollections } = useContext(DashboardContext);

  const formattedTeam = (member: Member) => {
    return {
      id: member.id,
      name:
        member.first_name + (member.last_name ? " " + member.last_name : ""),
    };
  };

  const formattedCollection = (collection: Collection) => {
    return {
      id: collection.id,
      name: collection.name!,
    };
  };

  const mapMemberOptions = orgMembers.map(formattedTeam);
  const mapCollectionOptions = orgCollections.map(formattedCollection);

  const teamForm = useForm<TeamFormValues>({
    resolver: zodResolver(teamSchema),
    defaultValues: {
      name: "",
      status: "active",
      members: [],
      collections: [],
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = teamForm;

  useEffect(() => {
    if (props.team) {
      teamForm.reset({
        name: props.team.name || "",
        status: props.team.status || "active",
        members: props.team.members?.map(formattedTeam) || [],
        collections: props.team.collections?.map(formattedCollection) || [],
      });
    }
  }, []);

  const { setOrgTeams } = useContext(DashboardContext);

  /* modal controls */
  const [modalMessage, setModalMessage] = React.useState<any>(null);

  const createTeam = () => {
    setModalMessage(LoadingMessage());

    CreateTeam(
      {
        ...props.team,
        ...teamForm.getValues(),
      } as Team,
      logout
    )
      .then((response: any) => {
        setModalMessage(
          SucceessMessage("Team created successfully", props.setOpen)
        );
        /* update the appropriate context so that changes are reflected */
        FetchOrgTeams(logout).then((result: Team[]) => {
          setOrgTeams(result);
        });

        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((error) => {
        setModalMessage(
          ErrorMessage(
            error.message,
            props.setOpen as React.Dispatch<React.SetStateAction<boolean>>
          )
        );
      });
  };

  const updateTeam = () => {
    setModalMessage(LoadingMessage());

    UpdateTeam(
      {
        ...props.team,
        ...teamForm.getValues(),
      } as Team,
      logout
    )
      .then((response: any) => {
        setModalMessage(
          SucceessMessage("Team created successfully", props.setOpen)
        );
        /* update the appropriate context so that changes are reflected */
        FetchOrgTeams(logout).then((result: Team[]) => {
          setOrgTeams(result);
        });

        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((error) => {
        setModalMessage(
          ErrorMessage(
            error.message,
            props.setOpen as React.Dispatch<React.SetStateAction<boolean>>
          )
        );
      });
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>
        {!modalMessage ? (
          <Box>
            <Typography
              id="add-modal-description"
              variant="h6"
              color="secondary"
              gutterBottom
              sx={{ mt: 1 }}
            >
              {props.team ? "Edit " : "Add a new "} team
            </Typography>

            <FormProvider {...teamForm}>
              <form
                onSubmit={handleSubmit(props.team ? updateTeam : createTeam)}
                noValidate={true}
              >
                <Box>
                  <Input
                    name="name"
                    color="secondary"
                    id="teamName"
                    label="Enter Team Name"
                    type="search"
                    variant="standard"
                    fullWidth
                    margin="dense"
                  />

                  {props.team && (
                    <ToggleGroup
                      sx={{ p: 2 }}
                      name="status"
                      options={[
                        {
                          value: "active",
                          label: "Active",
                        },
                        {
                          value: "disabled",
                          label: "Disabled",
                        },
                      ]}
                      fullWidth
                      exclusive
                      size="small"
                      color="secondary"
                      id="status-select"
                      aria-labelledby="buttons-group-label"
                    />
                  )}
                  <Controller
                    name="members"
                    control={teamForm.control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        sx={{ p: 2 }}
                        multiple
                        limitTags={5}
                        id="members"
                        options={mapMemberOptions}
                        onChange={(_, newValue) => field.onChange(newValue)}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        getOptionLabel={(option: {
                          id: string;
                          name: string;
                        }) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="secondary"
                            variant="standard"
                            label="Members"
                            placeholder="Add Members"
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="collections"
                    control={teamForm.control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        sx={{ p: 2 }}
                        multiple
                        limitTags={5}
                        id="collections"
                        options={mapCollectionOptions}
                        onChange={(_, newValue) => field.onChange(newValue)}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option: {
                          id: string;
                          name: string;
                        }) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="secondary"
                            variant="standard"
                            label="Collections"
                            placeholder="Add Collections"
                          />
                        )}
                      />
                    )}
                  />

                  <br />
                  <br />
                </Box>
                <Stack direction="row" sx={{ bottom: 0 }}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                    fullWidth
                    sx={{ m: 1 }}
                  >
                    SUBMIT
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    sx={{ m: 1 }}
                    onClick={() => {
                      props.setOpen(false);
                    }}
                  >
                    CANCEl
                  </Button>
                </Stack>
              </form>{" "}
            </FormProvider>
          </Box>
        ) : (
          modalMessage
        )}
      </Box>
    </Modal>
  );
}
