import React, { useContext, useEffect } from "react";

import { Modal, Box, Typography, Stack, Button } from "@mui/material";

import { ModalStyle2 } from "src/Components/modalStyle";

import {
  CreateCollection,
  FetchOrgCollections,
  UpdateCollection,
} from "src/API/getUserData";
import DashboardContext from "src/Contexts/dashboardContext";

import { Collection } from "src/types/Collection";

import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "src/Components/ui/Input";
import { ToggleGroup } from "src/Components/ui/ToggleGroup";

import {
  LoadingMessage,
  SucceessMessage,
  ErrorMessage,
} from "../../dashboardModals/modalComponents/messageComponents";
import UserContext from "src/Contexts/userContext";

const collectionSchema = z.object({
  name: z.string().min(1, "Collection name is required"),
  status: z.enum(["active", "disabled"], {
    errorMap: () => ({ message: "Please select a status" }),
  }),
});

type CollectionFormValues = z.infer<typeof collectionSchema>;

export default function CollectionModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  collection: Collection | null;
}) {
  const { logout } = useContext(UserContext);

  const collectionForm = useForm<CollectionFormValues>({
    resolver: zodResolver(collectionSchema),
    defaultValues: {
      name: "",
      status: "active",
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = collectionForm;

  useEffect(() => {
    if (props.collection) {
      collectionForm.reset({
        name: props.collection.name || "",
        status: props.collection.status || "active",
      });
    }
  }, []);

  const { setOrgCollections } = useContext(DashboardContext);

  /* modal controls */
  const [modalMessage, setModalMessage] = React.useState<any>(null);

  const createCollection = () => {
    setModalMessage(LoadingMessage());

    CreateCollection(
      {
        ...props.collection,
        ...collectionForm.getValues(),
      } as Collection,
      logout
    )
      .then((response: any) => {
        setModalMessage(
          SucceessMessage("Collection created successfully", props.setOpen)
        );
        /* update the appropriate context so that changes are reflected */
        FetchOrgCollections(logout).then((result: Collection[]) => {
          setOrgCollections(result);
        });

        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((error) => {
        setModalMessage(
          ErrorMessage(
            error.message,
            props.setOpen as React.Dispatch<React.SetStateAction<boolean>>
          )
        );
      });
  };

  const updateCollection = () => {
    setModalMessage(LoadingMessage());

    UpdateCollection(
      {
        ...props.collection,
        ...collectionForm.getValues(),
      } as Collection,
      logout
    )
      .then((response: any) => {
        setModalMessage(
          SucceessMessage("Collection created successfully", props.setOpen)
        );
        /* update the appropriate context so that changes are reflected */
        FetchOrgCollections(logout).then((result: Collection[]) => {
          setOrgCollections(result);
        });

        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((error) => {
        setModalMessage(
          ErrorMessage(
            error.message,
            props.setOpen as React.Dispatch<React.SetStateAction<boolean>>
          )
        );
      });
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>
        {!modalMessage ? (
          <Box>
            <Typography
              id="add-modal-description"
              variant="h6"
              color="secondary"
              gutterBottom
              sx={{ mt: 1 }}
            >
              {props.collection ? "Edit " : "Add a new "} collection
            </Typography>

            <FormProvider {...collectionForm}>
              <form
                onSubmit={handleSubmit(
                  props.collection ? updateCollection : createCollection
                )}
                noValidate={true}
              >
                <Box>
                  <Input
                    name="name"
                    color="secondary"
                    id="collectionName"
                    label="Enter Collection Name"
                    type="search"
                    variant="standard"
                    fullWidth
                    margin="dense"
                  />

                  {props.collection && (
                    <ToggleGroup
                      sx={{ p: 2 }}
                      name="status"
                      options={[
                        {
                          value: "active",
                          label: "Active",
                        },
                        {
                          value: "disabled",
                          label: "Disabled",
                        },
                      ]}
                      fullWidth
                      exclusive
                      size="small"
                      color="secondary"
                      id="status-select"
                      aria-labelledby="buttons-group-label"
                    />
                  )}

                  <br />
                  <br />
                </Box>
                <Stack direction="row" sx={{ bottom: 0 }}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                    fullWidth
                    sx={{ m: 1 }}
                  >
                    SUBMIT
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    sx={{ m: 1 }}
                    onClick={() => {
                      props.setOpen(false);
                    }}
                  >
                    CANCEl
                  </Button>
                </Stack>
              </form>{" "}
            </FormProvider>
          </Box>
        ) : (
          modalMessage
        )}
      </Box>
    </Modal>
  );
}
