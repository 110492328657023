import { createContext } from "react";
import { IErrorModal } from "src/Components/ErrorModal";

export interface IAppContext {
  modalInfo: IErrorModal;
  setModalInfo: (info: IErrorModal) => void;
}

const AppContext = createContext<any>({
  modalInfo: {},
  setModalInfo: (info: IErrorModal) => null,
});

export default AppContext;
