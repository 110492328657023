import { useContext, useEffect, useState } from "react";
import UserContext from "../../Contexts/userContext";
import {
  Box,
  CssBaseline,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useNavigate } from "react-router";
import { config } from "../../Config/config";
import * as z from "zod";

import { NextStepButton } from "src/Config/styling";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "src/Components/ui/Input";
import { supabase } from "src/supabase/supabaseClient";
import { FetchUserMemberInfo, FetchUserOrgInfo } from "src/API/getUserData";
import { Organization } from "src/types/Organization";
import { Member } from "src/types/Member";

const loginSchema = z.object({
  email: z
    .string()
    .email("Please enter a valid email address")
    .min(1, "Email is required"),
  otp: z.string().max(6, "Please enter a 6-digit passcode").optional(), // not actually optional, but want to allow submission of form for requestOTP. Stored as string to maintain leading zeros
});

type LoginFormValues = z.infer<typeof loginSchema>;

const LoginForm = () => {
  const { login, logout } = useContext(UserContext);
  const navigate = useNavigate();

  const form = useForm<LoginFormValues>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      otp: undefined,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const [passcodeSent, setPasscodeSent] = useState(false);

  const [snackbarInfo, setSnackbarInfo] = useState<{
    open: boolean;
    message?: string;
  }>({ open: false });

  /* auto-submit OTP verivication when 6 digits are entered  */
  useEffect(() => {
    if (form.getValues("otp")?.toString().length === 6) {
      handleSubmit(verifyOTP)();
    }
  }, [form.watch("otp")]);

  const requestOTP = async () => {
    form.setValue("otp", undefined);
    const { error } = await supabase.auth.signInWithOtp({
      email: form.getValues("email"),
      options: {
        shouldCreateUser: false, // set this to false if you do not want the user to be automatically signed up
      },
    });

    if (error) {
      setSnackbarInfo({ open: true, message: error.message });
    } else {
      setPasscodeSent(true);
      setSnackbarInfo({
        open: true,
        message:
          "We've emailed you a one-time passcode; enter it below to login",
      });
    }
  };

  const verifyOTP = async () => {
    const {
      data: { session },
      error,
    } = await supabase.auth.verifyOtp({
      email: form.getValues("email"),
      token: form.getValues("otp")!,
      type: "email",
    });

    if (error) {
      setSnackbarInfo({ open: true, message: error.message });
    } else {
      FetchUserOrgInfo(logout)
        .then((orgInfo: Organization) => {
          FetchUserMemberInfo(logout)
            .then((memberInfo: Member) => {
              login({
                orgInfo: orgInfo,
                memberInfo: memberInfo,
              });
              navigate("/dashboard", { replace: true });
            })
            .catch((err) => {
              throw err;
            });
        })
        .catch((err) => {
          setSnackbarInfo({
            open: true,
            message: err,
          });
        });
    }
  };

  return (
    <Stack sx={{ overflow: "scroll" }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 3,
          height: "100vh",
          overflow: "auto",
          padding: 10,
        }}
      >
        <Paper
          elevation={4}
          sx={{
            mt: 10,
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 350,
            width: 500,
            position: "relative",
          }}
        >
          <Typography variant="h6" align="center" p={3}>
            Log in via one-time passcode
          </Typography>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(requestOTP)} noValidate={true}>
              {!passcodeSent ? (
                <Stack spacing={2}>
                  <Input
                    name="email"
                    label="Email"
                    type="email"
                    variant="outlined"
                    size="small"
                  />
                  <NextStepButton
                    type="submit"
                    variant="contained"
                    size="small"
                    disabled={isSubmitting || !form.formState.isValid}
                  >
                    Send Passcode
                  </NextStepButton>
                </Stack>
              ) : (
                <Stack direction={"row"} alignItems="center">
                  <Tooltip title={"Back"} placement="bottom" arrow>
                    <IconButton
                      edge="end"
                      aria-label="back to email"
                      onClick={() => setPasscodeSent(false)}
                    >
                      <ArrowBackIosNewIcon />
                    </IconButton>
                  </Tooltip>
                  <Stack spacing={2} width={"100%"} padding={2}>
                    <Input
                      name="otp"
                      label="Passcode"
                      variant="outlined"
                      type="otp"
                      size="small"
                      autoComplete="one time passcode"
                      onInput={(e: any) => {
                        e.target.value = e.target.value.toString().slice(0, 6);
                      }}
                    />
                  </Stack>
                </Stack>
              )}
            </form>
          </FormProvider>
          {isSubmitting && <LinearProgress color="primary" sx={{ m: 2 }} />}

          <Typography
            variant="body1"
            align="center"
            sx={{ fontStyle: "italic", position: "absolute", bottom: 10 }}
          >
            Download the NextStep browser on{" "}
            <Link
              href={config.chromeExtensionURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Chrome
            </Link>{" "}
            or{" "}
            <Link
              href={config.edgeExtensionUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Microsoft Edge
            </Link>
          </Typography>
        </Paper>
        <Snackbar
          key={"login snackbar"}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ zIndex: 5000, mt: 8, position: "absolute" }}
          open={snackbarInfo.open}
          onClose={() => setSnackbarInfo({ open: false })}
          message={snackbarInfo.message || ""}
        />
      </Box>
    </Stack>
  );
};

export default LoginForm;
