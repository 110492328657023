import { createContext } from "react";
import { Collection } from "src/types/Collection";
import { Member } from "src/types/Member";
import { Team } from "src/types/Team";
import { ProcessGroup } from "src/types/ProcessGroup";

export interface IDashboardContext {
  orgMembers: Member[];
  setOrgMembers: (members: Member[]) => void;
  orgCollections: Collection[];
  setOrgCollections: (collections: Collection[]) => void;
  orgTeams: Team[];
  setOrgTeams: (teams: Team[]) => void;
  stepHistory: {
    stepLog: [];
    errorLog: [];
  };
  setStepHistory: (history: any) => void;
  orgProcessGroups: ProcessGroup[];
  setOrgProcessGroups: (processGroups: ProcessGroup[]) => void;
}

const DashboardContext = createContext<IDashboardContext>({
  orgMembers: [],
  setOrgMembers: (members: Member[]) => null,
  orgCollections: [],
  setOrgCollections: (members: Collection[]) => null,
  orgTeams: [],
  setOrgTeams: (teams: Team[]) => null,
  stepHistory: {
    stepLog: [],
    errorLog: [],
  },
  setStepHistory: (history: any) => null,
  orgProcessGroups: [],
  setOrgProcessGroups: (processGroups: ProcessGroup[]) => null,
});

export default DashboardContext;
