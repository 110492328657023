import React from "react";

import {
  Modal,
  Box,
  Alert,
  Typography,
  Stack,
  Button,
  AlertColor,
  AlertPropsColorOverrides,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";

import { useContext } from "react";
import AppContext from "../Contexts/appContext";

import { ModalStyle } from "./modalStyle";

export interface IErrorModal {
  open: boolean;
  severity:
    | OverridableStringUnion<AlertColor, AlertPropsColorOverrides>
    | undefined;
  message: string;
  hideButton: boolean;
}

export default function ErrorModal(props: IErrorModal) {
  const { setModalInfo } = useContext(AppContext);
  return (
    <Modal
      open={props.open}
      onClose={() => {
        setModalInfo({ open: false });
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <Alert severity={props.severity || "error"}>
          {props.severity ? props.severity.toUpperCase() : "ERROR"}
        </Alert>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {props.message}
        </Typography>
        <Stack sx={{ bottom: 0 }}>
          <br />
          {!props.hideButton && (
            <Button
              color="secondary"
              variant="outlined"
              hidden={props.hideButton || false}
              onClick={() => {
                setModalInfo({ open: false });
              }}
            >
              Close
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
}
