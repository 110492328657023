import React, { useContext, useEffect } from "react";

import {
  Modal,
  Box,
  Typography,
  Stack,
  Button,
  LinearProgress,
  TextField,
  Autocomplete,
} from "@mui/material";

import { ModalStyle2 } from "../../../../Components/modalStyle";

import {
  LoadingMessage,
  SucceessMessage,
  ErrorMessage,
} from "../../dashboardModals/modalComponents/messageComponents";

import DashboardContext from "../../../../Contexts/dashboardContext";
import UserContext from "../../../../Contexts/userContext";
import { Member } from "src/types/Member";
import { Team } from "src/types/Team";
import {
  FetchOrgTeams,
  FetchTeamProcesses,
  UpdateMember,
} from "src/API/getUserData";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { MemberProcessMapping } from "src/types/Process";

export const teamMemberSchema = z.object({
  processes: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        processGroupName: z.string(),
        collectionName: z.string(),
      })
    )
    .optional(),
});

type TeamMemberFormValues = z.infer<typeof teamMemberSchema>;

export default function TeamMemberModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  member: Member;
  team: Team;
}) {
  const { orgMembers, setOrgTeams } = useContext(DashboardContext);
  const { logout } = useContext(UserContext);

  const teamMemberForm = useForm<TeamMemberFormValues>({
    resolver: zodResolver(teamMemberSchema),
    defaultValues: {
      processes: [],
    },
  });

  /* org seq info */
  const [teamProcessList, setTeamProcessList] = React.useState<
    MemberProcessMapping[] | undefined
  >(undefined);

  useEffect(() => {
    FetchTeamProcesses(props.team.id, logout).then((result) => {
      setTeamProcessList(result as MemberProcessMapping[]);

      teamMemberForm.setValue(
        "processes",
        result.filter((process: MemberProcessMapping) =>
          props.member.mappedProcessIds?.includes(process.id)
        )
      );
    });
  }, []);

  const memberInfo = orgMembers.find(
    (item: any) => props.member === item.email
  );

  /* modal controls */
  const [modalMessage, setModalMessage] = React.useState<any>(null);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = teamMemberForm;

  const updateAssigments = () => {
    /* determine which sequces needed to be added / removed from member's profile;
    cannot simply rip and replace since some assigned sequences may not be editable by the current team */

    setModalMessage(LoadingMessage());
    const initialProcessIds = props.member.mappedProcessIds || [];

    /* identify the process IDs that cannot be editied in the contest of this team; these will be added back to the submission object */
    const outOfScopeProcessIds = initialProcessIds.filter((id) => {
      return !teamProcessList?.find((seq) => seq.id === id);
    });

    const selectedProcessIds = teamMemberForm
      .getValues("processes")
      ?.map((process) => process.id);

    UpdateMember(
      {
        id: props.member.id,
        mappedProcessIds: outOfScopeProcessIds.concat(selectedProcessIds || []),
      } as Member,
      logout
    )
      .then(() => {
        /* update the appropriate context so that changes are reflected - currently this causes the modal to close too quickly, but fixing this is a later problem */
        FetchOrgTeams(logout)
          .then((result: Team[]) => {
            setOrgTeams(result);
          })
          .then(() => {});

        setModalMessage(
          SucceessMessage(
            "Member processes updated successfully",
            props.setOpen
          )
        );
        setTimeout(() => {
          props.setOpen(false);
        }, 5000);
      })
      .catch((err) => {
        setModalMessage(ErrorMessage(err.message, props.setOpen));
      });
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>
        <FormProvider {...teamMemberForm}>
          <form onSubmit={handleSubmit(updateAssigments)} noValidate={true}>
            {!modalMessage ? (
              <Box>
                <Typography
                  id="modal-modal-description"
                  variant="h6"
                  gutterBottom
                  sx={{ mt: 1 }}
                >
                  Edit process assignments for <br />
                  <b>
                    {props.member.first_name} {props.member.last_name}
                  </b>
                </Typography>
                {!teamProcessList && <LinearProgress color="secondary" />}
                {teamProcessList && (
                  <Controller
                    name="processes"
                    control={teamMemberForm.control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        sx={{ p: 2 }}
                        multiple
                        limitTags={5}
                        id="members"
                        options={teamProcessList.sort(
                          (a, b) =>
                            -b.collectionName.localeCompare(a.collectionName)
                        )}
                        groupBy={(option) => option.collectionName}
                        onChange={(_, newValue) => field.onChange(newValue)}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option: MemberProcessMapping) =>
                          option.name
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="secondary"
                            variant="standard"
                            label="Processes"
                            placeholder="Add Processes"
                          />
                        )}
                      />
                    )}
                  />
                )}
                <br />
                <Stack direction="row" sx={{ bottom: 0 }}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    sx={{ m: 1 }}
                  >
                    SUBMIT
                  </Button>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    sx={{ m: 1 }}
                    onClick={() => {
                      props.setOpen(false);
                    }}
                  >
                    CANCEl
                  </Button>
                </Stack>
              </Box>
            ) : (
              modalMessage
            )}
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
