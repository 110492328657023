import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { StepContent } from "@mui/material";

const steps = [
  {
    title: "Capture",
    subtitle: "Step 1",
    description: "Capture the processes your team members need to perform",
  },
  {
    title: "Configure",
    subtitle: "Step 2",
    description:
      "Leverage user and role based access to provide a customized user experience",
  },
  {
    title: "Deliver",
    subtitle: "Step 3",
    description:
      "Members have access to the information they need, right when they need it",
  },
];

const finalMessage =
  "From onboarding to continuing education, NextStep is your solution for knowledge management";

export default function FeatureStepper(props: { isMobile: boolean }) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <React.Fragment>
      {!props.isMobile && ( // desktop version
        <Box sx={{ mx: 20 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={step.title} {...stepProps}>
                  <StepLabel {...labelProps}>{step.title}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Typography sx={{ mt: 8, mb: 5, fontSize: "30px" }}>
            {activeStep === steps.length
              ? finalMessage
              : steps[activeStep].description}
          </Typography>
          <Box
            sx={{ display: "flex", mx: "auto", flexDirection: "row", pt: 2 }}
          >
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              size="large"
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Previous
            </Button>

            <Button
              size="large"
              disabled={activeStep === steps.length}
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </Box>
      )}

      {props.isMobile && ( // mobile version
        <Box sx={{ mx: 2 }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.title}>
                <StepLabel>{step.title}</StepLabel>
                <StepContent>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {step.description}
                  </Typography>
                  <Box sx={{ my: 1 }}>
                    <Button
                      size="large"
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Previous
                    </Button>

                    <Button
                      size="large"
                      disabled={activeStep === steps.length}
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box>
                </StepContent>
              </Step>
            ))}
            {activeStep === steps.length && (
              <Box>
                <br />
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {finalMessage}
                </Typography>
                <Button onClick={() => setActiveStep(0)} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Box>
            )}
          </Stepper>
        </Box>
      )}
    </React.Fragment>
  );
}
