
import React, { useContext, useEffect, useMemo } from "react";
import {
  Fab,
  FormControl,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import { ProcessFC } from "./process";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import { Select } from "../../../Components/ui/Select";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  ProcessFormProvider,
  processFormSchema,
  ProcessFormValues,
} from "./context/ProcessFormContext";
import ProcessModal from "./libraryModals/processModal";

import { type ProcessGroup } from "src/types/ProcessGroup";
import { ChainedProcess, type Process } from "src/types/Process";

import { FetchProcess, FetchProcesses } from "src/API/getUserData";
import { Step } from "src/types/Step";
import UpdateProcessModal from "./libraryModals/updateProcessModal";
import UserContext from "src/Contexts/userContext";

export default function ProcessGroup(props: { processGroups: ProcessGroup[] }) {
  const { logout } = useContext(UserContext);

    const { processGroups } = props;
    useEffect(() => {
      setProcesses([]);
      setSelectedProcess(undefined);
    }, [processGroups]);

    const [processModalOpen, setProcessModalOpen] = React.useState(false);

    const [updateProcessModalOpen, setUpdateProcessModalOpen] =
      React.useState<boolean>(false);
    const [updateProcessModalInfo, setUpdateProcessModalInfo] = React.useState<{
      action: string;
      processInfo: Process;
    }>({
      action: "",
      processInfo: {} as Process,
    });

    const [processes, setProcesses] = React.useState<Process[]>([]);
    const [selectedProcess, setSelectedProcess] = React.useState<
      Process | undefined
    >(undefined);

    const processOptions = useMemo(() => {
      return Object.entries(processes).map(([, processObj]) => {
        return {
          value: processObj.id,
          label: processObj.name || "",
        };
      });
    }, [processes]);

    const [selectedProcessGroup, setSelectedProcessGroup] =
      React.useState<ProcessGroup | null>(null);

    const processGroupOptions = useMemo(() => {
      return Object.entries(processGroups).map(([, processGroupObj]) => {
        return {
          value: processGroupObj.id,
          label: processGroupObj.name || "",
        };
      });
    }, [processGroups]);

    const form = useForm<ProcessFormValues>({
      resolver: zodResolver(processFormSchema),
      defaultValues: {
        processGroupId: "",
        process: {
          name: "",
          description: "",
          url: "",
          steps: [] as Step[],
          chained_processes: [] as ChainedProcess[],
        },
      },
    });

    const handleGroupSelect = (event: SelectChangeEvent<unknown>) => {
      setProcesses([]);
      setSelectedProcess(undefined);

      const selectedGroup: ProcessGroup | undefined = processGroups.find(
        (group: ProcessGroup) => group.id === event.target.value
      );
      if (selectedGroup) {
        setSelectedProcessGroup(selectedGroup);
        form.reset({
          processGroupId: selectedGroup.id as string,
        });

        FetchProcesses(selectedGroup.id, logout)
          .then((result: Process[]) => {
            setProcesses(result);
          })
          .catch((err) => {});
      }
    };

    const handleProcessSelect = (event: SelectChangeEvent<unknown>) => {
      event.preventDefault();
      form.reset({
        processGroupId: selectedProcessGroup?.id,
      });
      const selectedProcess: Process | undefined = processes.find(
        (process: Process) => process.id === event.target.value
      );
      if (selectedProcess) {
        setSelectedProcess(selectedProcess);
        FetchProcess(selectedProcess.id, logout)
          .then((result: Process) => {
            console.log(result);
            form.reset({
              processGroupId: selectedProcessGroup?.id,
              process: {
                description: result.description || "",
                url: result.url || "",
                name: result.name,
                id: result?.id,
                status: result.status,
                steps: (result.steps as Step[]) || [],
                chained_processes:
                  (result.chained_processes as ChainedProcess[]) || [],
              },
            });
          })
          .catch((err) => {
            //handle error
          });
      }
    };

    const onSubmit = (data: ProcessFormValues) => {
      const processObj = {
        ...data.process,
      } as Process;

      setUpdateProcessModalInfo({
        action: selectedProcess ? "edit" : "add",
        processInfo: processObj,
      });
      setUpdateProcessModalOpen(true);
    };

    const onInvalid = (errors: any) => {
      //workaround to allow submission when validation fails ('draft' status only)
      if (form.watch("process.status") === "draft") onSubmit(form.getValues());
    };

    return (
      <FormProvider {...form}>
        <ProcessFormProvider value={form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, onInvalid)}
            noValidate={true}
          >
            <Stack direction={"row"} spacing={1} sx={{ display: "flex" }}>
              <Stack sx={{ flex: 1 }}>
                <Select
                  name="processGroupId"
                  label="Process Group"
                  onChange={handleGroupSelect}
                  options={processGroupOptions}
                  disabled={!processGroups?.length}
                />
              </Stack>
              <Stack sx={{ flex: 1 }}>
                <Stack
                  direction="row"
                  width="full"
                  spacing={1}
                  alignItems="center"
                >
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      name="process"
                      label="Process"
                      options={processOptions}
                      value={selectedProcess?.id || ""}
                      onChange={handleProcessSelect}
                      disabled={
                        !form.watch("processGroupId") ||
                        processOptions.length === 0
                      }
                    />
                  </FormControl>

                  <Tooltip title="Edit Process Name" placement="top">
                    <span>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setProcessModalOpen(true);
                        }}
                        disabled={
                          !selectedProcess || !form.watch("processGroupId")
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <Tooltip title="Add Process" placement="top">
                    <span>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedProcess(undefined);
                          form.reset({
                            processGroupId: form.getValues("processGroupId"),
                          });
                          setProcessModalOpen(true);
                        }}
                        disabled={!form.watch("processGroupId")}
                      >
                        <AddIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Stack>
              </Stack>
            </Stack>
            <br />
            {selectedProcess && form.watch("process.name") ? (
              <ProcessFC
                groupProcesses={processes.map((process: Process) => {
                  return { id: process.id, name: process.name };
                })}
              />
            ) : (
              <Typography
                fontStyle="italic"
                color="divider"
                variant="h5"
                py={2}
              >
                Select a Process to view & edit details here
              </Typography>
            )}

            {!form.formState.isDirty ||
              (!form.formState.isValid &&
                form.watch("process.status") !== "draft" && (
                  <Tooltip
                    title={
                      "Correct errors or change to 'Draft' status to submit"
                    }
                    placement="top-start"
                    arrow
                  >
                    <Fab
                      variant="extended"
                      sx={{
                        position: "fixed",

                        bottom: "30px",
                        right: "260px",
                      }}
                      color="secondary"
                    >
                      <HelpOutlineIcon />
                    </Fab>
                  </Tooltip>
                ))}
            <Fab
              type="submit"
              variant="extended"
              sx={{
                position: "fixed",
                pl: 10,
                pr: 10,
                bottom: "30px",
                right: "30px",
              }}
              color="secondary"
              disabled={
                !form.formState.isDirty ||
                (!form.formState.isValid &&
                  form.watch("process.status") !== "draft")
              }
            >
              Submit
            </Fab>
          </form>
        </ProcessFormProvider>
        {processModalOpen && selectedProcessGroup && (
          <ProcessModal
            open={processModalOpen}
            setOpen={setProcessModalOpen}
            processGroupId={selectedProcessGroup.id}
            process={selectedProcess || null}
            callback={() => {
              FetchProcesses(selectedProcessGroup.id, logout)
                .then((result: Process[]) => {
                  setProcesses(result);
                })
                .catch(() => {
                  setProcesses([]);
                });
            }}
          />
        )}
        {updateProcessModalOpen && (
          <UpdateProcessModal
            open={updateProcessModalOpen}
            setOpen={setUpdateProcessModalOpen}
            info={updateProcessModalInfo}
          />
        )}
      </FormProvider>
    );
}
