import React, { useEffect, useReducer } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import Dashboard from "./Pages/Dashboard/dashboard";
import MenuAppBar from "./Components/header";
import HomePage from "./Pages/Home/Home";
import AboutPage from "./Pages/About/About";
import ContactPage from "./Pages/Contact/Contact";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

import "./App.css";
import UserContext, { IUserContext } from "./Contexts/userContext";
import AppContext, { IAppContext } from "./Contexts/appContext";

import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { DynamicTheme } from "./Config/styling";
import ErrorModal, { IErrorModal } from "./Components/ErrorModal";

import { Snackbar, useMediaQuery, Fab } from "@mui/material";
import { supabase } from "./supabase/supabaseClient";
import { FetchUserMemberInfo, FetchUserOrgInfo } from "./API/getUserData";
import { Organization } from "./types/Organization";
import { Login } from "@mui/icons-material";
import { Member } from "./types/Member";

const INITIAL_USER_STATE = {
  orgInfo: null,
  memberInfo: null,
} as IUserContext;

const INITIAL_APP_STATE = {
  modalInfo: { open: false } as IErrorModal,
} as IAppContext;

const userReducer = (
  state: IUserContext,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case "login": {
      sessionStorage.setItem("userInfo", JSON.stringify(action.payload));
      return {
        ...state,
        ...action.payload,
      };
    }
    case "logout":
      supabase.auth.signOut();
      sessionStorage.clear();

      return {
        ...state,
        userInfo: { highlight_color: "#FB8C37" },
        memberInfo: null,
      };
    case "updateUser":
      return {
        ...state,
        user: action.payload,
      };

    case "setOrgName":
      return {
        ...state,
        orgName: action.payload,
      };

    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
};

const appReducer = (
  state: IAppContext,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case "setModalInfo":
      return {
        ...state,
        modalInfo: action.payload as IErrorModal,
      };

    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
};

function App() {
  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange((_event, session) => {
      const localUserInfo = sessionStorage.getItem("userInfo");

      if (_event === "SIGNED_OUT") {
        currentUserState.logout(true);
      } else if (
        (_event === "SIGNED_IN" || _event === "INITIAL_SESSION") &&
        localUserInfo
      ) {
        /* SIGNED_IN is the generic, no-change event; load data from sessionStorage */
        currentUserState.login(JSON.parse(localUserInfo));
      } else if (_event === "TOKEN_REFRESHED") {
        FetchUserOrgInfo(currentUserState.logout)
          .then((orgInfo: Organization) => {
            FetchUserMemberInfo(currentUserState.logout)
              .then((memberInfo: Member) => {
                currentUserState.login({
                  orgInfo: orgInfo,
                  memberInfo: memberInfo,
                });
              })
              .catch((err) => {
                throw err;
              });
          })
          .catch((err) => {
            currentUserState.logout(true);
          });
      }
      data.subscription.unsubscribe();
    });
  }, []);

  const [userState, userDispatch] = useReducer(userReducer, INITIAL_USER_STATE);
  const [appState, appDispatch] = useReducer(appReducer, INITIAL_APP_STATE);

  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const currentUserState = {
    memberInfo: userState.memberInfo,
    orgInfo: userState.orgInfo,
    login: (data: any) =>
      userDispatch({
        type: "login",
        payload: data,
      }),
    logout: (involuntary?: boolean) => {
      if (involuntary) setSnackbarOpen(true);
      userDispatch({ type: "logout" });
    },
  };

  const currentAppState = {
    modalInfo: userState.modalInfo || {},
    setModalInfo: (info: IErrorModal) =>
      appDispatch({ type: "setModalInfo", payload: info }),
  };

  /* protected routes for logged in users only */
  const ProtectedRoute = () => {
    return userState.memberInfo ? <Outlet /> : <Navigate to="/" replace />;
  };

  return (
    <ThemeProvider
      theme={DynamicTheme(
        userState && userState.orgInfo ? userState.orgInfo.highlight_color : ""
      )}
    >
      <AppContext.Provider value={currentAppState}>
        <UserContext.Provider value={currentUserState}>
          <BrowserRouter>
            <MenuAppBar isMobile={useMediaQuery("(max-width: 900px)")} />
            <div className="App">
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path="dashboard/*" element={<Dashboard />} />
                </Route>

                <Route
                  path="/"
                  element={
                    <HomePage isMobile={useMediaQuery("(max-width: 900px)")} />
                  }
                />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/about" element={<AboutPage />} />
              </Routes>
            </div>
          </BrowserRouter>
        </UserContext.Provider>
        <ErrorModal {...appState.modalInfo} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message="Logged out due to inactivity"
          key={"logout snackbar"}
          sx={{ zIndex: 5000 }}
        />
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
