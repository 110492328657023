import React, { useContext, useEffect, useReducer } from "react";

import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import Overview from "../../Pages/Dashboard/Overview/overview";
import Reporting from "../../Pages/Dashboard/Reporting/reporting";
import Admin from "./Admin/Admin";
import Library from "./Library/library";
import Teams from "../../Pages/Dashboard/Teams/teams";

import DashboardMenu from "./sideMenu";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
  FetchOrgCollections,
  FetchOrgMembers,
  FetchOrgTeams,
  // GetOrgData,
  // GetUsageData,
} from "../../API/getUserData";
import UserContext from "../../Contexts/userContext";
import DashboardContext, {
  IDashboardContext,
} from "../../Contexts/dashboardContext";
import { Member } from "src/types/Member";
import { Team } from "src/types/Team";
import { Collection } from "src/types/Collection";
import { ProcessGroup } from "src/types/ProcessGroup";

const INITIAL_DASHBOARD_STATE = {
  orgMembers: [] as Member[],
  orgCollections: [] as Collection[],
  orgTeams: [] as Team[],
  stepHistory: {
    stepLog: [],
    errorLog: [],
  },
} as IDashboardContext;

const dashboardReducer = (
  state: IDashboardContext,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "setOrgMembers":
      return {
        ...state,
        orgMembers: action.payload,
      };
    case "setOrgCollections":
      return {
        ...state,
        orgCollections: action.payload,
      };
    case "setOrgTeams":
      return {
        ...state,
        orgTeams: action.payload,
      };
    case "setStepHistory":
      return {
        ...state,
        stepHistory: action.payload,
      };
    case "setOrgProcessGroups":
      return {
        ...state,
        orgProcessGroups: action.payload,
      };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
};

export default function Dashboard() {
  const { memberInfo } = useContext(UserContext);
  const { logout } = useContext(UserContext);

  const [dashboardState, dashboardDispatch] = useReducer(
    dashboardReducer,
    INITIAL_DASHBOARD_STATE
  );

  useEffect(() => {
    FetchOrgMembers(logout)
      .then((result: Member[]) => {
        dashboardDispatch({
          type: "setOrgMembers",
          payload: result || [],
        });
      })
      .catch((err) => {
        // handle error
      });

    FetchOrgTeams(logout)
      .then((result: Team[]) => {
        dashboardDispatch({
          type: "setOrgTeams",
          payload: result || [],
        });
      })
      .catch((err) => {
        // handle error
      });

    FetchOrgCollections(logout)
      .then((result: Collection[]) => {
        dashboardDispatch({
          type: "setOrgCollections",
          payload: result || [],
        });
      })
      .catch((err) => {
        // handle error
      });
  }, []);

  const currentDashboardState = {
    orgMembers: dashboardState.orgMembers || [],
    setOrgMembers: (orgMembers: Member[]) =>
      dashboardDispatch({ type: "setOrgMembers", payload: orgMembers }),

    orgCollections: dashboardState.orgCollections || [],
    setOrgCollections: (orgCollections: Collection[]) =>
      dashboardDispatch({ type: "setOrgCollections", payload: orgCollections }),

    orgTeams: dashboardState.orgTeams || [],
    setOrgTeams: (orgTeams: Team[]) =>
      dashboardDispatch({ type: "setOrgTeams", payload: orgTeams }),

    stepHistory: dashboardState.stepHistory || [],
    setStepHistory: (stepHistory: any) =>
      dashboardDispatch({ type: "setStepHistory", payload: stepHistory }),

    orgProcessGroups: dashboardState.orgProcessGroups || [],
    setOrgProcessGroups: (processGroups: ProcessGroup[]) =>
      dashboardDispatch({
        type: "setOrgProcessGroups",
        payload: processGroups,
      }),
  };

  /* protected routes for admins and managers in users only */
  const AdminRoute = () => {
    return memberInfo && memberInfo.role === "admin" ? (
      <Outlet />
    ) : (
      <Navigate to="/" replace />
    );
  };

  const ManagerRoute = () => {
    return memberInfo && memberInfo.role === "manager" ? (
      <Outlet />
    ) : (
      <Navigate to="/" replace />
    );
  };

  return (
    <DashboardContext.Provider value={currentDashboardState}>
      <Box
        component="main"
        sx={{
          display: "flex",
          backgroundColor: "primary.light",
        }}
      >
        <CssBaseline />
        <DashboardMenu />
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route element={<AdminRoute />}>
            {/* <Route path="/reporting" element={<Reporting />} /> */}
            <Route path="/admin" element={<Admin />} />
            <Route path="/library" element={<Library />} />
            <Route path="/teams" element={<Teams />} />
          </Route>
          <Route element={<ManagerRoute />}>
            {/* <Route path="/reporting" element={<Reporting />} /> */}
            <Route path="/library" element={<Library />} />
            <Route path="/teams" element={<Teams />} />
          </Route>
        </Routes>
      </Box>
      {/* </Box> */}
    </DashboardContext.Provider>
  );
}
